import Layout from "../common/layout"
import DetailMedia from "../company/DetailMedia/DetailMediaAward"
import Head from "../common/Head"
import { useFormatter } from "helpers/i18n"
import img from "images/Company/Media/Meta.png"

const EntrepreneurAward = () => {
  const { __ } = useFormatter()
  return (
    <>
      <Head
        showCaptcha={false}
        title={__({ defaultMessage: "Press Release" })}
        description={__({
          defaultMessage: `ONPOINT'S FOUNDER AND CEO RECEIVED "THE ENTREPRENEUR AWARD OF HO CHI MINH CITY"`,
        })}
        image={img}
      />
      <Layout>
        <DetailMedia />
      </Layout>
    </>
  )
}

export default EntrepreneurAward
